import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./navbar.css";

const NavBar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark">
      <Container>
        <Navbar.Brand href="#home" className="website-heading">
          TheStateJobs.com
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#coding">Coding</Nav.Link>
            <NavDropdown title="Languages" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#c">C</NavDropdown.Item>
              <NavDropdown.Item href="#Java">Java</NavDropdown.Item>
              <NavDropdown.Item href="#Javascript">Javascript</NavDropdown.Item>
              <NavDropdown.Item href="#Python">Python</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Databases" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#c">MySQL</NavDropdown.Item>
              <NavDropdown.Item href="#Java">MongoDB</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#aptitude">Aptitude</Nav.Link>
            <Nav.Link href="#reasoning">Reasoning</Nav.Link>
          </Nav>

          {/* <Nav>
            <Nav.Link href="#login">Login</Nav.Link>
            <Nav.Link href="#signup">Sign Up</Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
