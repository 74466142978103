import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Dashboard = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <div className="mb-4">
            <h3>Preparing for you next job? Everything you need is here!</h3>
            <p style={{ fontSize: "1.5rem" }}>
              Absolutely neat, clean, and crisp for you.
            </p>
          </div>
          <p>
            Are you a recent graduate or a fresher looking to break into the
            software industry? We’ve got you covered! From coding basics to
            interview prep, everything you need is right here.
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col>
          <h2>Why Choose Software?</h2>
          <p>
            The software industry is booming, and as a fresher, you have endless
            opportunities. With the right preparation, you can join top
            companies, work on cutting-edge technologies, and grow into a
            rewarding career.
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col>
          <h2>Freshers' Roadmap to Success</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>1. Beginner-Friendly Resources</h3>
          <ul>
            <li>
              Learn to Code: Start with beginner-friendly languages like{" "}
              <strong>Java, Python, or JavaScript</strong>.
            </li>
            <li>Learn these languages with easy and interesting examples.</li>
          </ul>
          <Button variant="link">Learn Java Basics</Button>
          <Button variant="link">Learn Python Basics</Button>
          <Button variant="link">Learn Javascript Basics</Button>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col>
          <h3>2. Written test Preparation</h3>
          <ul>
            <li>Brush up on logical reasoning and quantitative aptitude</li>
            <li>
              Common Coding Questions: Practice questions from companies like{" "}
              <strong>TCS, Infosys, Wipro</strong>, and more.
            </li>
          </ul>
          <Button variant="link">Logical Reasoning</Button>
          <Button variant="link">Quantitative Aptitude</Button>
          <Button variant="link">Top 100 Coding Questions for Freshers</Button>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col>
          <h3>3. Interview Preperation</h3>
          <ul>
            <li>
              Prepare for commonly asked interview questions in each language.
            </li>
          </ul>
          <Button variant="link">Java interview questions</Button>
          <Button variant="link">Python interview questions</Button>
          <Button variant="link">Javascript interview questions</Button>
        </Col>
      </Row>

      {/* TODO: Enable this once you start posting jobs */}
      {/* <hr />

      <Row className="mt-4">
        <Col>
          <h3>Fresher Resources You’ll Love</h3>
          <ul>
            <li>
              Job Alerts for Freshers: Get notified of the latest fresher job
              openings.
            </li>
          </ul>
          <Button variant="link">Fresher Jobs</Button>
        </Col>
      </Row> */}

      <p className="mt-4">
        This is all you need to get into a software job. With regular
        preparation and practice, you will be able to land your software job
        soon.
      </p>
      <p className="mt-4">All the very best!</p>
    </Container>
  );
};

export default Dashboard;
