import React from "react";
import { useParams } from "react-router-dom";

function JobPost() {
  // Access the jobid parameter from the URL
  const { jobid } = useParams();

  return (
    <div>
      <h1>Job Details</h1>
      <p>Job ID: {jobid}</p>
    </div>
  );
}

export default JobPost;
