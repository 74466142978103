import React, { useState } from "react";
import Login from "../login/Login";

function Admin() {
  const [loginSuccessFull, setLoginSuccessFull] = useState(false);

  console.log(loginSuccessFull);

  return (
    <div className="admin">
      {loginSuccessFull ? (
        <div className="post-jobs">create jobs</div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default Admin;
