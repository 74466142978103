import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import JobPost from "./components/jobpost/JobPost";
import Admin from "./components/admin/Admin";
import Dashboard from "./components/dashboard/Dashboard";
import NavBar from "./components/nav-bar/Navbar";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Container className="my-4">
        <NavBar />
      </Container>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/jobs/:jobid" element={<JobPost />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
