import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

function Footer() {
  return (
    <footer className="bg-light mt-4 p-3">
      <Container>
        <Row>
          <Col className="text-center">
            <p>
              &copy; {new Date().getFullYear()} TheStateJobs.com. All Rights
              Reserved.
            </p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button variant="link">Privacy Policy</Button>
              </li>
              <li className="list-inline-item">
                <Button variant="link">Terms of Service</Button>
              </li>
              <li className="list-inline-item">
                <Button variant="link">Contact Us</Button>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
